import React, { startTransition } from "react";

import { ApplicationContext } from "src/context";

import { ToastBarV2 } from "src/modules/toastBar";
import Toolbar, { ToolbarMobileV2 } from "src/layouts/components/toolbar";
import NavigationBar from "src/layouts/components/navigationbar";
import Styling from "src/layouts/components/styling/styling";
import MobileBackdrop, { MobileNavigation } from "src/layouts/components/mobilemenu";
import Profiler from "src/components/profiler/profiler.v1";
import MobileToolbar from "src/layouts/components/mobileToolbar";
import NavigationFooter from "src/layouts/components/navigationfooter";
import MobileToolbarFooterWrapper from "src/layouts/components/toolbar/mobile.toolbar.footer.wrapper";
import Socket from "src/components/socket";
import FlyoutPromoBox from "src/modules/flyoutPromoBox";
import BackToTopButton from "src/modules/backToTopButton/back.to.top.button";
import InAppBackButton from "src/modules/inAppBackButton";
import FooterModule from "src/modules/footer";
import {dispatch} from "src/utils/events"
import LoginModalWatcher from "src/layouts/loginModalWatcher.v2";

// TODO : These must be placed on page context
import pages from "src/../src-dynamic/destination.pages";
import { defaultCurrency, currencies } from "src/../src-dynamic/destination.currencies";
import { languages } from "src/../src-dynamic/destination.languages";
import { isRunTime } from "src/utils/isRunTime";


type StandardLayoutProps = {
  destinationId: string;
  language: string;
  defaultLanguage: string;
  location: any;
  pageContext: TPagePageContext; // | TEntryPageContext | TOtherPageContext;
  children?: React.ReactNode;
  includeStyles?: boolean;
}

type StandardLayoutState = {
  scrolledDown: boolean;
  mobileMenuOpen: boolean;
  timestamp: number;
  isScrollingUp: boolean;
}


export default class StandardLayout extends React.Component<StandardLayoutProps, StandardLayoutState> {

  /** @ts-ignore */
  context!: React.ContextType<typeof ApplicationContext>;
  static contextType = ApplicationContext;

  public state: StandardLayoutState = {
    scrolledDown: false,
    mobileMenuOpen: false,
    timestamp: 0,
    isScrollingUp: false,
  }

  static TIMESPAN_PROCESS_SCROLL_EVENTS = 10;
  static SCROLLGAP_NEEDED_FOR_OPEN  = 90;
  static SCROLLGAP_NEEDED_FOR_CLOSE = 30;

  private scrollHandler = () => {
    const now = new Date().getTime();
    const valid = now - this.state.timestamp > StandardLayout.TIMESPAN_PROCESS_SCROLL_EVENTS;
    if (!valid) return;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollDifference = Math.abs(scrollTop - this.lastScrollTop);
    if (scrollDifference < 60) return;
    if (scrollTop > this.lastScrollTop) {
      // Scrolling down
      this.setState({ isScrollingUp: false });
    } else {
      // Scrolling up
      this.setState({ isScrollingUp: true });
    }
    this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling

    if (this.state.scrolledDown) {
      const scrolled = !(window.scrollY < StandardLayout.SCROLLGAP_NEEDED_FOR_CLOSE);
      this.setState({scrolledDown: scrolled, timestamp: new Date().getTime()});
    } else {
      const scrolled = window.scrollY > StandardLayout.SCROLLGAP_NEEDED_FOR_OPEN;
      this.setState({scrolledDown: scrolled, timestamp: new Date().getTime()});
    }
  }

  toggleMobileMenu = () => {
    this.setState({mobileMenuOpen: !this.state.mobileMenuOpen});
  }

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) this.setState({mobileMenuOpen: false});
  }

  toggleLoginModal = () => {
    dispatch('login-modal');
  }

  shouldComponentUpdate(nextProps: Readonly<StandardLayoutProps>, nextState: Readonly<StandardLayoutState>, nextContext: any): boolean {
    if (!this.props.location || !nextProps.location) return true;
    if (this.props.location.pathname !== nextProps.location.pathname) return true;
    if (this.props.pageContext !== nextProps.pageContext) return true;
    if (this.state.scrolledDown !== nextState.scrolledDown) return true;
    if (this.state.mobileMenuOpen !== nextState.mobileMenuOpen) return true;
    if (this.props.language !== nextProps.language) return true;
    if (this.state.isScrollingUp !== nextState.isScrollingUp) return true;
    return false;
  }

  componentDidUpdate(prevProps: Readonly<StandardLayoutProps>, prevState: Readonly<StandardLayoutState>, snapshot?: any): void {
    if (!prevProps.location) return;

    if (prevProps.location.pathname !== this.props.location.pathname && this.state.mobileMenuOpen === true) {
      this.closeMobileMenu();
    }
  }

  componentDidMount() {
    startTransition(() => {
      const { layout } = this.props.pageContext;
      const { navigation_bar, back_to_top_button } = layout;

      // FLM-7107 -> Avoid chatbot from overflowing modals.

      if (navigation_bar?.collapse_on_scroll === true || back_to_top_button) {
        this.scrollHandler();
        document.addEventListener('scroll', this.scrollHandler);
      }
      if (!isRunTime()) return;
      setTimeout(() => {
        let button = null;
        let shadowRoot = null;
        const shadowHost = document.getElementById('tdWebchat');
        if (shadowHost) shadowRoot = shadowHost.shadowRoot;
        if (shadowRoot) button = shadowRoot.querySelector('button');
        if (button) button.style.zIndex = "3";
      }, 1000)
    })
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollHandler);
  }

  public get scrolledDown() {
    const { layout } = this.props.pageContext;
    const { navigation_bar } = layout;
    if (navigation_bar?.collapse_on_scroll === false) return false;
    return this.state.scrolledDown;
  }
  
  public get mobileToolbarDisplay() {
    const { toolbar } = this.props.pageContext.layout;
    if (toolbar?.items.filter(item => item.item.mobile_fixed_at_top === true).length === 0 && !toolbar.with_currency_selector && !toolbar.with_language_selector && !toolbar.user_profile && !toolbar.shopping_cart) return "hidden";
    return toolbar?.mobile_toolbar_display;  
  }

  public get style(): React.CSSProperties {
    const destinationId = this.props.pageContext.destinationId;
    const layout = this.props.pageContext.layout;

    let colors: Record<string, string>;

    /* default colors of Spotlio */
    colors = {
      "--primaryColor": "#6D62E4",
      "--secondaryColor": "#ffffff"
    }

    /* Gausta */
    if (destinationId === "6a9444ac-2764-4d7d-aa44-1594b5f1ba95"){
      colors = {
        "--primaryColor": "#0B2752",
        "--secondaryColor": "#A4DCDC"
      }
    }

    /* Gaustablikk */
    if (destinationId === "04b74e2f-67d7-4ff2-81c3-78fba8b082ac"){
      colors = {
        "--primaryColor": "#ec6807",
        "--secondaryColor": "#f6f1e5"
      }
    }

    /* Gaustatoppen */
    if (destinationId === "3adca828-ef64-48d3-976b-00ad254508f6"){
      colors = {
        "--primaryColor": "#493F39",
        "--secondaryColor": "#EBEBDE"
      }
    }

    /* Turufjell */
    if (destinationId === "4b655a27-6768-4daa-a656-da9dc3578549"){
      colors = {
        "--primaryColor": "#493F39",
        "--secondaryColor": "#EBEBDE"
      }
    }

    /* Tyinfilefjell & Tyinfilefjell lite */
    if (destinationId === "2ebc9a7b-1397-491b-9213-437513ce8a95" || destinationId === "7f398469-aab1-47fa-bc65-eef3ad772b8f"){
      colors = {
        "--primaryColor": "#ec6807",
        "--secondaryColor": "#f6f1e5"
      }
    }

    // TODO : remove above hardcodings when all layouts are fully configured.
    if (layout?.hyperlink_text_color?.value) colors['--hyperlink-text-color'] = layout.hyperlink_text_color.value;
    if (layout?.hyperlink_hover_color?.value) colors['--hyperlink-hover-color'] = layout.hyperlink_hover_color.value;
    if (layout?.primary_color?.value) colors['--primaryColor'] = layout.primary_color.value;
    if (layout?.secondary_color?.value) colors['--secondaryColor'] = layout.secondary_color.value;
    if (layout?.highlighted_color?.value) colors['--highlightedColor'] = layout.highlighted_color.value;

    return colors as React.CSSProperties;
  }

  public render() {
    const { location, pageContext } = this.props;
    const { layout, language, defaultLanguage } = pageContext;
    const { navigation_bar, toastbar, toolbar, back_to_top_button, in_app_back_button, layout_display, footer,  } = layout;
    const isInApp = this.context.isInApp === true;

    return <Profiler id="layout-template">
      <main className="standard" style={this.style}>

      {this.props.includeStyles === true && layout ? <Styling layout={layout} className="mainapp" /> : null}

      { (toolbar && (!isInApp || (toolbar.user_profile || toolbar.shopping_cart))) ? <Profiler id="toolbar">
        <Toolbar
          language={language}
          languages={languages}
          page_translations={this.props.pageContext.translations}
          pages={pages}
          toolbar={toolbar}
          scrolledDown={this.scrolledDown}
          isScrollingUp={this.state.isScrollingUp}
          defaultLanguage={defaultLanguage}
          defaultCurrency={defaultCurrency}
          currencies={currencies}
          onLoginButtonClick={this.toggleLoginModal}
          closeMenuFn={() => this.closeMobileMenu()}
        />
         <MobileToolbar
          isInApp={isInApp}
          language={language}
          languages={languages}
          page_translations={this.props.pageContext.translations}
          pages={pages}
          toolbar={toolbar}
          scrolledDown={this.scrolledDown}
          isScrollingUp={this.state.isScrollingUp}
          defaultLanguage={defaultLanguage}
          defaultCurrency={defaultCurrency}
          currencies={currencies}
          onLoginButtonClick={this.toggleLoginModal}
          closeMenuFn={() => this.closeMobileMenu()}
        />
      </Profiler> : null }
      {(navigation_bar && !isInApp) ? <Profiler id="navigation-bar"><NavigationBar
        logoPageTarget={navigation_bar.logo_page_target}
        location={location}
        language={language}
        languages={languages}
        defaultLanguage={defaultLanguage}
        defaultCurrency={defaultCurrency}
        currencies={currencies}
        pages={pages}
        page_translations={this.props.pageContext.translations}
        navigation_bar={navigation_bar}
        has_toolbar_above={!!toolbar}
        mobile_toolbar_display={this.mobileToolbarDisplay}
        scrolled_down={this.scrolledDown}
        isScrollingUp={this.state.isScrollingUp}
        mobile_menu_open={this.state.mobileMenuOpen}
        onMobileMenuClick={this.toggleMobileMenu}
        onLoginButtonClick={this.toggleLoginModal}
      /></Profiler> : null }

      {(toastbar && !isInApp) ? <Profiler id="toast-bar"><ToastBarV2
        scrolledDown={this.scrolledDown}
        toastbar={toastbar}
        language={this.props.language}
        defaultLanguage={this.props.defaultLanguage}
      /></Profiler> : null }

      {navigation_bar && !isInApp && this.state.mobileMenuOpen
        ? <Profiler id="mobile-backdrop"><MobileBackdrop
          open={this.state.mobileMenuOpen}
          navigation_bar={navigation_bar}
          flyout_promo_box={navigation_bar?.flyout_promo_box_mobile_and_tablet ? <FlyoutPromoBox mobile={true} flyoutPromoBox={navigation_bar.flyout_promo_box_mobile_and_tablet} language={language} defaultLanguage={defaultLanguage} /> : null}
          footer={<MobileToolbarFooterWrapper toolbar={toolbar}>
            {toolbar ? <ToolbarMobileV2
              language={language}
              languages={languages}
              page_translations={this.props.pageContext.translations}
              pages={pages}
              toolbar={toolbar}
              scrolledDown={this.scrolledDown}
              isScrollingUp={this.state.isScrollingUp}
              defaultLanguage={defaultLanguage}
              defaultCurrency={defaultCurrency}
              currencies={currencies}
              onLoginButtonClick={this.toggleLoginModal}
              closeMenuFn={() => this.closeMobileMenu()}
            /> : null}
            <Profiler id="mobile-navigation-footer">
            <NavigationFooter
              language={language}
              languages={languages}
              defaultLanguage={defaultLanguage}
              defaultCurrency={defaultCurrency}
              currencies={currencies}
              pages={pages}
              toolbar={toolbar}
              navigation_bar={navigation_bar}
              page_translations={this.props.pageContext.translations}
              onLoginButtonClick={this.toggleLoginModal}
             />
             </Profiler>
          </MobileToolbarFooterWrapper>}>
            <MobileNavigation
              collapse_first_level_items={navigation_bar.collapse_first_level_items}
              location={location}
              language={language}
              defaultLanguage={defaultLanguage}
              items={navigation_bar.navigation_items}
            />
          </MobileBackdrop>
          </Profiler>
        : null }

      { back_to_top_button && !isInApp
        ? <BackToTopButton button={back_to_top_button} standard_cta_button={pageContext.layout.cta_type} scrolled_down={this.state.scrolledDown}/>
        : null}

      {isInApp
        ? in_app_back_button
          ? <InAppBackButton button={in_app_back_button} standard_cta_button={pageContext.layout.cta_type} hasNavigationBar={Boolean(navigation_bar)}/>
          : <InAppBackButton button={{
              style: pageContext.layout.cta_type || "square",
              background_color: pageContext.layout.cta_background_color || { value: '#000000' },
              icon_color: pageContext.layout.cta_text_color || { value: '#FFFFFF' },
            }} standard_cta_button={pageContext.layout.cta_type || 'square'} hasNavigationBar={Boolean(navigation_bar)} />
        : null}

      {this.props.children}

      { footer && !isInApp
        ? <Profiler id="footer"><FooterModule language={language} defaultLanguage={defaultLanguage} footer={footer}/></Profiler>
        : null}

      {!isInApp ? <Socket backgroundColor={footer?.powered_by_spotlio_bar_background_color?.value} color={footer?.powered_by_spotlio_color?.value}/> : null}

      <LoginModalWatcher language={pageContext.language} />
    </main>
    </Profiler>
  }
}