import React from "react";
import Icon from "src/components/icon";
import MobileNavigationLink from "./mobile.navigation.link";
import MobileNavigationSubMenuWithLink from "./mobile.navigation.submenu.with.link";

import { getLanguageField } from "src/utils/language";

type Props = {
    key: string;
    depth: number;
    language: string;
    defaultLanguage: string;
    item: TNavigationItem;
    collapse_first_level_items: boolean;
    onClick?: () => void;
    onDimension?: (width: number) => void;
}

type State = {
    isCollapsed: boolean;
}

export default class MobileNavigationSubMenu extends React.Component<Props, State> {
    state = {
        isCollapsed: true
    }
    
    private get items(){
        return (this.props.item?.items || []).filter(item => item.hide !== "yes")
    }

    private getChevron = () => {
        if (!this.props.collapse_first_level_items || !this.items.length) return null;
        return <Icon 
            className={`h-4 w-4 ml-1 cursor-pointer`} 
            icon={this.state.isCollapsed ? "ChevronDown" : "ChevronUp"} 
        />
    }

    public get label() {
        return getLanguageField(this.props.item.translations, this.props.language, 'label');
    }

    private get style(): any {
        return {
            '--text-color': this.props.item.item_color?.value,
            '--hover-color': this.props.item.item_hover_color?.value,
        }
    }
  
    render(): React.ReactNode {
        const {label, getChevron} = this;
        const {language, defaultLanguage, collapse_first_level_items} = this.props;

        return <>
            <div 
                style={this.style} 
                onClick={() => this.setState({ isCollapsed: !this.state.isCollapsed })} 
                className="navBarItems mobile-navigation-submenu-label text-[color:var(--text-color)]"
            >
                {label} {getChevron()}
            </div>
            {this.state.isCollapsed && collapse_first_level_items ? null : (this.items || []).map((item, idx) => {
                if (item.item_type === "Link") return <MobileNavigationLink depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`mobnavbar_link_${idx}`} item={item} />
                if (item.item_type === "Menu") return <MobileNavigationSubMenu collapse_first_level_items={collapse_first_level_items} depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`mobnavbar_menu_${idx}`} item={item} />
                if (item.item_type === "Menu with link") return <MobileNavigationSubMenuWithLink collapse_first_level_items={collapse_first_level_items} depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`mobnavbar_menu_${idx}`} item={item} />
                return null;
            })}
        </>;
    }
}