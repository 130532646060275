import React from "react";

import Link from "src/components/link";
import Icon from "src/components/icon";
import MobileNavigationLink from "./mobile.navigation.link";
import MobileNavigationSubMenu from "./mobile.navigation.submenu";

import { getLanguageField } from "src/utils/language";
import { getSlug } from "src/utils/slugs";

type Props = {
    key: string;
    depth: number;
    language: string;
    defaultLanguage: string;
    item: TNavigationItem;
    collapse_first_level_items?: boolean;
}

type State = {
    isCollapsed: boolean;
}

export default class MobileNavigationSubMenuWithLink extends React.Component<Props, State> {
    state = {
        isCollapsed: true
    }
    
    private get label() {
        return getLanguageField(this.props.item.translations, this.props.language, 'label');
    }

    private get link(): string {
        return this.props.item.link_type === 'external'
            ? getLanguageField(this.props.item.link_translations, this.props.language, 'link_href') as string
            : getSlug(this.props.language, this.props.defaultLanguage, this.props.item.link_page?.translations || []);
    }

    public hasColor() {
        if (this.props.item.item_color) return true;
        return false;
    }

    private get color() {
        if (this.props.depth >= 2 && !this.hasColor()) return "text-[color:var(--link-subitems-text-color)]"
        return "text-[color:var(--text-color)] hover:text-[color:var(--hover-color)]";
    }

    private get font() {
        if (this.props.depth >= 2) return "navBarSubItems"
        return "navBarItems"
    }

    private get style(): any {
        return {
            '--text-color': this.props.item.item_color?.value,
            '--hover-color': this.props.item.item_hover_color?.value,
        }
    }

    private get items() {
        return (this.props.item?.items || []).filter(item => item.hide !== "yes")
    }

    private getChevron = () => {
        if (!this.props.collapse_first_level_items || !this.items.length) return null;
        return <Icon 
            style={this.style} 
            className={`${this.color} ml-1 cursor-pointer`} 
            icon={this.state.isCollapsed ? "ChevronDown" : "ChevronUp"} 
            onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                this.setState({ isCollapsed: !this.state.isCollapsed });
            }} 
        />
    }
  
    render(): React.ReactNode {
        const {label, link} = this;
        const {language, defaultLanguage, collapse_first_level_items} = this.props;

        return <>
            <div className="mobile-navigation-submenu-label flex items-center">
                <Link 
                    style={this.style} 
                    key={this.props.key} 
                    title={label as string} 
                    href={link} 
                    className={`${this.font} mobile-navigation-link navigation-item-depth-${this.props.depth} ${this.color}`}
                >
                    {label}
                </Link>
                {this.getChevron()}
            </div>
            {this.state.isCollapsed && collapse_first_level_items ? null : (this.items || []).map((item, idx) => {
                if (item.item_type === "Link") return <MobileNavigationLink depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`mobnavbar_link_${idx}`} item={item} />
                if (item.item_type === "Menu") return <MobileNavigationSubMenu depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`mobnavbar_menu_${idx}`} item={item} />
                if (item.item_type === "Menu with link") return <MobileNavigationSubMenuWithLink collapse_first_level_items={collapse_first_level_items} depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`mobnavbar_menu_${idx}`} item={item} />
                return null;
            })}
        </>;
    }
}