import React from "react";

import MobileNavigationLink from "./mobile.navigation.link";
import MobileNavigationSubMenu from "./mobile.navigation.submenu";
import MobileNavigationSubMenuWithLink from "./mobile.navigation.submenu.with.link";

import Icon from "src/components/icon";
import Accordion from "src/components/accordion";

import { getLanguageField } from "src/utils/language";
import { getSlug, isSameLocation } from "src/utils/slugs";



type Props = {
    location: TGatsbyLocation;
    depth: number;
    language: string;
    defaultLanguage: string;
    item: TNavigationItem;
    collapse_first_level_items: boolean;
}


class MobileMenuLabel extends React.Component<{
    label: string;
    style: any;
    menu: MobileNavigationMenu;
    onClick?: () => null;
}> {

    get iconClass() {
        if (this.props.menu.isOpen()) return "rotate-180";
        return "";
    }

    toggleAccordion() {
        if (this.props.menu.isOpen()) return this.props.menu.close();
        return this.props.menu.open();
    }

    render() {
        return <>
            <div style={this.props.style} className={`navBarText mobile-navigation-menu-label ${this.props.menu.isOpen() ? 'mb-4' : ''} pb-2 border-b border-[#D9D9D9] text-[color:var(--text-color)] hover:text-[color:var(--hover-color)]`} onClick={this.props.onClick}>
                <span className="flex-1">{this.props.label}</span>
                <Icon className={this.iconClass} icon="ChevronDown" />
            </div>
        </>
    }
}


export default class MobileNavigationMenu extends React.Component<Props> {
    
    private accordionRef = React.createRef<Accordion>();

    public get label(): string {
        return getLanguageField(this.props.item.translations, this.props.language, 'label') as string;
    }

    public hasColor() {
        if (this.props.item.item_color) return true;
        return false;
    }

    public get color() {
        if (this.props.depth >= 1 && !this.hasColor()) return "text-[color:var(--menu-subitems-text-color)]"
        return "text-[color:var(--text-color)] hover:text-[color:var(--hover-color)]";
    }

    public getItemLink(item: TNavigationItem) {
        return item.link_type === 'external'
            ? getLanguageField(item.link_translations, this.props.language, 'link_href') as string
            : getSlug(this.props.language, this.props.defaultLanguage, item.link_page?.translations || []);
    }

    public itemsLinkIsCurrentlyActive(items: TNavigationItem[] = []) {
        if (!this.props.location) return false;

        for (const item of items) {
            if (item.item_type === "Link") {
                if (isSameLocation(this.props.location.pathname, this.getItemLink(item))) {
                    return true;
                }
            }
            if (item.item_type === "Menu") {
                if (this.itemsLinkIsCurrentlyActive(item.items) === true) return true;
            }
        }
        return false;
    }

    public isOpen() {
        return this.accordionRef.current?.state.open || false;
    }

    public open() {
        this.accordionRef.current?.open();
    }

    public close() {
        this.accordionRef.current?.close();
    }

    private get style(): any {
        return {
            '--text-color': this.props.item.item_color?.value,
            '--hover-color': this.props.item.item_hover_color?.value,
        }
    }

    private get items(){
        return (this.props.item?.items || []).filter(item => item.hide !== "yes")
    }

    render(): React.ReactNode {
        const {label} = this;
        const {language, defaultLanguage, collapse_first_level_items} = this.props;

        return <>
        <div className={`relative mobile-navigation-menu ${this.color}`}>
            <Accordion doNotCloseByClicks={true} doNotCloseByOthers={true} initiallyOpen={this.itemsLinkIsCurrentlyActive(this.props.item.items)} ref={this.accordionRef} extraHeight={20} opener={<MobileMenuLabel style={this.style} menu={this} label={label} />}>
                <div className="flex flex-col gap-4 justify-start pl-4 overflow-hidden">
                    {this.items.map((item, idx) => {
                        if (item.item_type === "Link") return <MobileNavigationLink depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`mobnavbar_link_${idx}`} item={item} />
                        if (item.item_type === "Menu") return <MobileNavigationSubMenu collapse_first_level_items={collapse_first_level_items} depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`mobnavbar_menu_${idx}`} item={item} />
                        if (item.item_type === "Menu with link") return <MobileNavigationSubMenuWithLink collapse_first_level_items={collapse_first_level_items} depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`mobnavbar_menu_${idx}`} item={item} />
                        return null;
                    })}
                </div>
            </Accordion>
        </div>
        </>;
    }
}