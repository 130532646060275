import React from "react";

import MobileNavigationLink from "./mobile.navigation.link";
import MobileNavigationMenu from "./mobile.navigation.menu";

import "./mobile.navigation.scss";

type MobileNavigationProps = {
    location: TGatsbyLocation;

    language: string;
    defaultLanguage: string;
    items: Array<{ position: number; item: TNavigationItem; }>;
    collapse_first_level_items: boolean;

}

export default class MobileNavigation extends React.Component<MobileNavigationProps> {
       
    render(): React.ReactNode {
        const {defaultLanguage, language, location, collapse_first_level_items} = this.props;
        return <>
        <div className="p-6 pb-0 gap-8 flex flex-col mobile-navigation">
            {this.props.items.map(({item}, idx) => {
                if (item.item_type === "Link") return <MobileNavigationLink depth={0} key={`mobnavbar_link_${idx}`} item={item} language={language} defaultLanguage={defaultLanguage} />
                if (item.item_type === "Menu") return <MobileNavigationMenu collapse_first_level_items={collapse_first_level_items} ocation={location} depth={0} key={`mobnavbar_menu_${idx}`} item={item} language={language} defaultLanguage={defaultLanguage} />
                if (item.item_type === "Menu with link") return <MobileNavigationMenu lcollapse_first_level_items={collapse_first_level_items} ocation={location} depth={0} key={`mobnavbar_menu_${idx}`} item={item} language={language} defaultLanguage={defaultLanguage} />
                return null;
            })}
        </div>
        </>;
    }
}